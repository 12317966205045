import React from 'react'
import NothingOfYourInterest from './NothingOfYourInterest'


import './OuterContainer.scss'

const OuterContainer = ({


    subTitle,
    description,
    containerStyle,
    innerContainerStyle,
    subTitle_Style,

}) => {
    return (
        <div
            className='outerContainer'
            style={containerStyle}
        >
            <NothingOfYourInterest
                    subTitle_Style={subTitle_Style}
                    containerStyle={innerContainerStyle}
                    subTitle={subTitle}
                    description={description}
                    
                    />
        </div>
    )
}

export default OuterContainer
