import * as React from 'react'
import JobDescriptionDisplay from '../components/JobDescriptionDisplay/JobDescriptionDisplay'
import Layout from "../components/layout/layout"
import { useState } from 'react'

import CostumerContainer from '../components/CostumerContainer/CostumerContainer'
import BenifitsContainer from '../components/BenifitsContainer/BenifitsContainer'



import MicrosoftDP from '../components/Images/MicrosoftDP.png'
import WalmartDP from '../components/Images/WalmartDP.png'
import HubSpotDP from '../components/Images/HubSpotDP.png'
import AirBnbDP from '../components/Images/AirBnbDP.png'
import FedExDP from '../components/Images/FedExDP.png'
import GoogleDP from '../components/Images/GoogleDP.png'



import dollarIcon from '../components/Images/dollar-sign.svg'
import heartIcon from '../components/Images/heart-sign.svg'
import homeIcon from '../components/Images/home-sign.svg'
import cutleryIcon from '../components/Images/cutlery-sign.svg'
import mortarBoardIcon from '../components/Images/mortarboard-sign.svg'
import feedingBottleIcon from '../images/001-feeding-bottle.png'
import terrainIcon from '../components/Images/terrain-sign.svg'


import coin from '../components/Images/Coin.png'
import heartRate from '../components/Images/HeartRate.png'
import home from '../components/Images/Home.png'
import meal from '../components/Images/Meal.png'
import books from '../components/Images/Books.png'
import drink from '../components/Images/Drink.png'


import LandingPageHeadingAndTagline from '../components/LandingPageHeadingAndTagline/LandingPageHeadingAndTagline'
import NothingOfYourInterest from '../components/NothingOfYourInterest/NothingOfYourInterest'
import ContactPanel from '../components/ContactPanel/ContactPanel'
import OuterContainer from '../components/NothingOfYourInterest/OuterContainer'

import careers from '../components/Images/Careers.png'
import { Container } from 'reactstrap'
import { Helmet } from "react-helmet"


const Careers = () => {




  // const [NameState, setNameState] = useState('')
  // const [EmailState, setEmailState] = useState('')
  // const [LinkedInIdState, setLinkedInIdState] = useState('')
  // const [PhoneState, setPhoneState] = useState('')
  // const [CompanyState, setCompanyState] = useState('')
  // const [MessageState, setMessageState] = useState('')

  // const [isLoading, setIsLoading] = useState(false)

  // const nameRegex = /^[a-z ,.'-]+$/gi
  
  // const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g
   
  // const phoneRegex = /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/g

  // const companyRegex = /^(\(([^)]+)\))?[[:punct:]]?\p{Lu}+(?:[\s'-]?[\p{L}\d]+)+(\(([^)]+)\))*$/g

  // const handleChange = (e, setState, validate, re) => {
  //   const newValue = e.target.value
  //   // if(validate(newValue, re)){
  //     setState(newValue)
  //   // }
  //   // else{
  //   //   alert("Invalid Characters")
  //   // }
  // }

  // const handleSubmit = async () => {
  //   try{
  //     setIsLoading(true)
  //     if(NameState==='' ||
  //     LinkedInIdState==='' ||
  //     EmailState==='' ||
  //     CompanyState==='' ||
  //     MessageState==='' ||
  //     PhoneState===''){
  //       alert("Please fill the asterik fields")
  //     }
  //     else{
  //       await fetch('https://dnnae-api-prod-bdhweedyzq-uc.a.run.app/public/contactForm/send',{
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json'
  //         },
  //         body: JSON.stringify({
  //           name: NameState,
  //           linkedInId: LinkedInIdState,
  //           workEmail: EmailState,
  //           companySize: CompanyState,
  //           message: MessageState,
  //           phone: PhoneState
  //         })

  //       })
          
  //       setIsLoading(false)
  //       alert("Message Sent")
  //     }
  //   }
  //   catch(e) {
      
  //     setIsLoading(false)
  //     console.log(e)
      
  //   }
  // }
    return (
  <div>
      <Helmet title="DNNae | Careers"/>
        <Layout
            containerStyle={{
              position: 'relative'
            }}
            selectedState='careers'
            pageTitle='Careers'
            buttonStatus={true}
            contactSalesLink='/#contactSalesPanel'
        >
          <Container
          
            style={{
              maxWidth: '1920px'
            }}
          >

          <LandingPageHeadingAndTagline

            heading='Join us on our mission'
            headingColorStyle={{color: '#9B51E0'}}
            tagline='We’re driven by making a quantifiable impact on people’s lives—and we’re always on the lookout for great talent to help us do it.'
            // conatinerStyle={{background: 'rgba(155, 81, 224, 0.06)'}}
            buttonName='View Open Roles'
            buttonHref='#jobDescriptionDisplay'
            buttonContainerStyle={{width: '250px'}}
            pngSrc={careers}
            containerClassName='landingPageHeadingAndTagline_Container_Careers'
            pngSrcClassName='landingPageHeadingAndTagline_Image_Careers'
          
          />

          <JobDescriptionDisplay

            thisId='heroDropperFunctionality'
            headingName='Play your part'
            containerStyle={{
                margin:'120px 7%'
            }}

          />

    {/* 
                    <CostumerContainer 
                        containerStyle={{
                            margin: '0px 7%'
                        }}
                        mainLogos ={[
                            {pngSrc: AirBnbDP},
                            {pngSrc: HubSpotDP},
                            {pngSrc: GoogleDP},
                            {pngSrc: MicrosoftDP},
                            {pngSrc: WalmartDP},
                            {pngSrc: FedExDP}
                        ]}
                        
                        />
    */}


          <BenifitsContainer

              containerStyle={{
                  margin:'0px 0% 120px 0%',
              }}
              
              firstArray={[
                  {id: 1,
                  pngSrc: coin,
                  description: 'Competitive salary and equity'
                  },{id: 1,
                  pngSrc: heartRate,
                  description: 'Health insurance for you and family'
                  },{id: 3,
                  pngSrc: home,
                  description: 'Remote work capabilities'
                  },{id: 4,
                  pngSrc: meal,
                  description: 'Meals on the job  '
                  },{id: 5,
                  pngSrc: books,
                  description: 'Learning and development stipend '
                  },{id: 6,
                  pngSrc: drink,
                  description: 'Quarterly offsite with the team'
                  }
              ]}
          />

          <OuterContainer

              subTitle='Nothing of your interest?'
              description="Hit us up at <a href='mailto:admin@dnnae.com'>admin@dnnae.com</a> anyways, we never say no to great talent"
              subTitle_Style={{
                fontSize: '37px',
                lineHeight: '57px'
              }}
              innerContainerStyle={{
                background: '#F7FAFF',
                borderRadius: '12px',
                padding: '70px 14%',
                width: '100%'
              }}

          />

          {/* <ContactPanel

          // containerStyle={{
          //   margin: '120px 7%'
          // }}
          heading='Contact us'
          subTitle='Book a demo now'
          statement1='Schedule a free personalized session today to learn more about DNNae and how we can help you crush your hiring goals.'
          statement2= "<br/>Fill out this form or contact us at <a href='mailto:sales@dnnae.com'>sales@dnnae.com</a>"
          handleSubmit={handleSubmit}
          isLoading={isLoading}
          formRequirements= {[
            {
              id: 1,
              label:'Your Name',
              type: 'text',
              value: NameState,
              setState: setNameState,
              re: nameRegex,
              handleChange
            },{
              id: 2,
              label:'Your LinkedIn ID',
              type: 'text',
              value: LinkedInIdState,
              setState: setLinkedInIdState,
              handleChange
            },{
              id: 3,
              label:'Work Email',
              type: 'email',
              value: EmailState,
              setState: setEmailState,
              re: emailRegex,
              handleChange
            },{
              id: 4,
              label:'Phone',
              type: 'text',
              value: PhoneState,
              setState: setPhoneState,
              re: phoneRegex,
              handleChange
            },{ 
              id: 5,
              label:'Company Size',
              type: 'text',
              value: CompanyState,
              setState: setCompanyState,
              re: companyRegex,
              handleChange
            },{
              id: 6,
              label:'Message',
              type: 'textarea',
              value: MessageState,
              setState: setMessageState,
              handleChange
            }
          ]}
          
          /> */}
        </Container>
      </Layout>            
            
        </div>
    )
}

export default Careers
