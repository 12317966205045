import React from 'react'


import InfoSummaryCardsMapContainer from '../InfoSummaryCardsMapContainer/InfoSummaryCardsMapContainer'
import './JobDescriptionDisplay.scss'

import '../GlobalStyle.scss'
import JobDescriptionCall from './JobDescriptionCall/JobDescriptionCall'


    const JobDescriptionDisplay = ({

    thisId='',
    containerStyle={},
    headingName,


}) => {
    return (
        <div 
            id='jobDescriptionDisplay'
            className='jobDescriptionDisplay'
            style={{...containerStyle}}    
            
        >

            <h1>{headingName}</h1>

            <JobDescriptionCall
            
                jobsInfo={[
                    {
                        jobTitle:'Full Stack Engineer',
                        jobDetail:'Contract, Islamabad',
                        buttonName: 'View Details',
                        mainLinkTo:'/jobDescriptionFullStack',
                    },{
                        jobTitle:'Full Stack Engineer   ',
                        jobDetail:'San Francisco, US',
                        mainLinkTo:'/jobDescriptionFullStackUS',
                        buttonName: 'View Details',
                    },{
                        jobTitle:'NLP Engineer',
                        jobDetail:'San Francisco, US',
                        mainLinkTo:'/jobDescriptionNLP',
                        buttonName: 'View Details',
                    },{
                        jobTitle:'Deep Learning Engineer',
                        jobDetail:'San Francisco, US',
                        mainLinkTo:'/jobDescriptionDeepLearning',
                        buttonName: 'View Details',
                    },{
                        jobTitle:'Deep Learning Engineer - Speech',
                        jobDetail:'San Francisco, US',
                        mainLinkTo:'/jobDescriptionDeepLearningSpeech',
                        buttonName: 'View Details',
                    },{
                        jobTitle:'Quality Assurance Engineer',
                        jobDetail:'Islamabad, Pakistan',
                        mainLinkTo:'/jobDescriptionQA',
                        buttonName: 'View Details',
                    }
                ]}
            
            />
        </div>
    )
}

export default JobDescriptionDisplay


// ,{
//     containerStyle:{width: '42%'},
//     mainHeading:'User Researcher',
//     mainDescription:'Contract, Remote',
//     mainButtonLabel: 'View details',
// }










