import React from 'react'

import './BenifitsContainer.scss'
import '../GlobalStyle.scss'
import BenifitsDisplayer from './BenifitsDisplayer'



const BenifitsContainer = ({

    firstArray,
    containerStyle={},

}) => {
    return (
        <div 
        
            style={{...containerStyle}}
            className='benifitsContainer'
            
        >
            <h3>Benifits</h3>
            <h2>We’ve got you covered</h2>
            <BenifitsDisplayer
                subjectArray={firstArray}
            />
        </div>
    )
}

export default BenifitsContainer
