import React from 'react'


import './BenifitsDisplayer.css'
import '../GlobalStyle.scss'

const BenifitsDisplayer = ({

    subjectArray,

}) => {
    return (
        <span className='benifitsDisplayer'>
            
            {subjectArray.map((benifit , index) => {
                return(
                    <div key={index}>
                        <img src={benifit.pngSrc}/>
                        <p>
                            {benifit.description}
                        </p>
                    </div>
                )
            })}

        </span>
    )
}

export default BenifitsDisplayer
