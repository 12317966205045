import React from 'react'
import { Button } from 'reactstrap'
import { Link } from 'gatsby'

import './JobDescriptionCall.scss'


const JobDescriptionCall = ({


    jobsInfo

}) => {
    return (
        <div
            className='jobDescriptionCall'
        >
            {
                jobsInfo.map((job , index) => {
                    return(
                        <span
                            className='jobCard'
                        >
                            <div>
                                <h1>{job.jobTitle}</h1>
                                <p>{job.jobDetail}</p>
                            </div>
                            <span
                                style={{
                                    maxWidth: '125px',
                                    minWidth: '125px'
                                }}
                            >
                                <Link
                                    className='linkControl'
                                    to={job.mainLinkTo}
                                >
                                    <Button
                                        outline
                                    >
                                        {job.buttonName}
                                    </Button>
                                </Link>

                            </span>
                        </span>
                    )
                })
            }
        </div>
    )
}

export default JobDescriptionCall
